import { useMemo, useState } from "react";
import styles from "./Rating.module.scss";
import cn from 'classnames';

type RatingProps = {
    value: number | undefined;
    onSend: (value: number) => void;
};

const translations = (window as any).app.preloadState.translation;

export const Rating = ({ value, onSend }: RatingProps) => {
    const [rating, setRating] = useState(value ?? 0);
    const isRated = useMemo(() => rating > 0, [rating]);

    const send = (rate: number) => () => {
        if (rating > 0) return;
        setRating(rate);
        onSend(rate);
    }

    return <div className={styles.rating}>
        {isRated ? <>
            <h4>{translations["rating.thankYouHeader"]}</h4>
            <p>{translations["rating.thankYouDescription"]}</p>
        </> : <>
            <h4>{translations["rating.rateHeader"]}</h4>
            <p>{translations["rating.rateDescription"]}</p>
        </>}
        <div className={styles.stars}>
            {[...Array(5).keys()].map(i => <div key={i} className={cn(styles.star, (rating > i) && styles.filled, isRated && styles.disabled)} onClick={send(i + 1)}></div>) }
        </div>
    </div>;
}
