import { InputText } from "ui";
import styles from "./delivery-methods.module.scss";
import { DeliveryOptionType } from "ui/src/types";
import { DeliverySummary, Order } from "../../types";

const removeComma = (value: string) => {
    let trimmedValue = value.trim();
    if (trimmedValue[trimmedValue.length - 1] === ",") {
        trimmedValue = trimmedValue.slice(0, -1);
    }
    return trimmedValue;
}

export const DeliveryMethods = ({ deliverySummary }: {
    deliverySummary: DeliverySummary | null
}) => {
    const translations = window.app.preloadState.translation;

    if (!deliverySummary) return null;
    return (<div className={styles.deliveryInput}>
            <h3 className={styles.title}>{translations["orderConfirmation.deliveryOptions.Title"]}</h3>
            <div>
                <div className={styles.deliveryMean}>
                    {translations["orderConfirmation.deliveryOptions." + deliverySummary.methodName]}
                </div>

                <InputText
                    name="deliveryAddress"
                    label={translations["orderConfirmation.deliveryOptions.SelectDeliveryAddress"]}
                    value={removeComma(deliverySummary.option ?? "")}
                    readonly={true}
                />
            </div>
        </div>);
};

export const DeliveryOptions = ({ deliverySummary }: {
    deliverySummary: DeliverySummary | null
}) => {
    const translations = window.app.preloadState.translation;

    if (!deliverySummary) return null;
    return (
        <div className={styles.deliveryInput}>
            <h3 className={styles.title}>{translations["orderConfirmation.deliveryOptions.DeliveryOptionsHeading"]}</h3>
            <div>
                <div className={styles.deliveryMean}>
                    {translations[deliverySummary.optionType === DeliveryOptionType.Full
                        ? "orderConfirmation.deliveryOptions.FullDelivery"
                        : "orderConfirmation.deliveryOptions.PartialDelivery"
                    ]}
                </div>
                <div className={styles.deliveryDescription} dangerouslySetInnerHTML={{
                    __html: deliverySummary.deliveryText
                }}>
                </div>
            </div>
        </div>);
};