// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hog5gHHrvk1vukTaMiNK{border-bottom:1px solid #434B4F26;box-shadow:0px 1px 0px #fff;color:#434b4f;padding-bottom:24px;margin-bottom:24px}@media(min-width: 0){.hog5gHHrvk1vukTaMiNK{font-size:2rem;line-height:2.4rem}}@media(min-width: 400px){.hog5gHHrvk1vukTaMiNK{font-size:2rem;line-height:2.4rem}}@media(min-width: 768px){.hog5gHHrvk1vukTaMiNK{font-size:2.6rem;line-height:2.8rem}}@media(min-width: 1366px){.hog5gHHrvk1vukTaMiNK{font-size:2.6rem;line-height:2.8rem}}.dKQfymwsmoV0yz3ITr77{display:flex;align-items:stretch;justify-content:space-between}@media(min-width: 0){.dKQfymwsmoV0yz3ITr77{padding:0 0 18px 0}}@media(min-width: 400px){.dKQfymwsmoV0yz3ITr77{padding:0 0 18px 0}}@media(min-width: 768px){.dKQfymwsmoV0yz3ITr77{padding:0 0 21px 0}}.dKQfymwsmoV0yz3ITr77 .Yfe7onuFzig9WzaiwivQ{flex:0 0 50%}.dKQfymwsmoV0yz3ITr77 .a5hGBoHaQe0nVwwHu0A7{flex:0 0 25%;display:flex;flex-direction:column;gap:8px}@media(max-width: 768.02px){.dKQfymwsmoV0yz3ITr77{flex-direction:column;gap:24px}.dKQfymwsmoV0yz3ITr77 .Yfe7onuFzig9WzaiwivQ,.dKQfymwsmoV0yz3ITr77 .a5hGBoHaQe0nVwwHu0A7{flex:0 0 auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "hog5gHHrvk1vukTaMiNK",
	"twoColumns": "dKQfymwsmoV0yz3ITr77",
	"personalInformation": "Yfe7onuFzig9WzaiwivQ",
	"address": "a5hGBoHaQe0nVwwHu0A7"
};
export default ___CSS_LOADER_EXPORT___;
